/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Prestamype from "@components/sharedComponents/customersLogos/logos/Prestamype.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"
import Cafae from "@components/sharedComponents/customersLogos/logos/Cafae.svg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageAbm/pagePandero/image/imageOne.png"
import ImageAccordionLeftTwo from "@components/pageAbm/pagePandero/image/imageTwo.png"
import ImageAccordionLeftThree from "@components/pageAbm/pagePandero/image/imageThree.png"

import imgStart from "@components/pageAbm/pagePandero/image/imageLogo.svg"
import imgBanner from "@components/pageAbm/pagePandero/image/banner.png"

const titleStart = (<p className="guide__title"> Hola, Pandero, <span style={{ color: '#0047BA' }}>supera ese crecimiento del 30% en ventas </span> menos de un año, automatizando el primer contacto con tus leads
</p>
)

const descripcionStart = (
    <p>
        Descubre cómo Beex puede ayudarte a mejorar tus métricas de ventas, automatizando el seguimiento de prospectos y optimizando tus procesos de conversión.
        <br /><br />
        Con nuestra plataforma omnicanal impulsada por IA, gestiona todo desde un solo lugar, acelera tu crecimiento y mejora la experiencia de tus clientes en cada interacción.
    </p>
)

const data = {
    start: {
        title: titleStart,
        textbody: descripcionStart,
        img: imgStart,
        banner: imgBanner,
        inputText: "Puesto en Pandero"
    },
    customersDesktop: [
        Rimac,
        Culqui,
        Alfin,
        Prestamype,
        Proempresa,
        Cafae
    ],
    customersTablet: {
        arrayOne: [Rimac,
            Culqui,
            Alfin,
            Prestamype,
            Proempresa,
            Cafae],
    },
    contacts: {
        title: "Conviértete en el fondo colectivo que convierte más con experiencias únicas",
        textbody: "Estás a un paso de revolucionar tus estrategias de marketing y ventas. Agenda una reunión para que uno de nuestros especialistas se involucre con tus objetivos.",
        href: "/hablemos/",
        btn: "Quiero una asesoría gratuita",
    },
    accordionRight: [
        {
            title: "Optimiza el primer contacto con bots en WhatsApp",
            description: "<p>Actualmente, el WhatsApp principal de Pandero se enfoca solo en vehículos, lo que confunde a los interesados en inmuebles. Además, los agentes piden los mismos datos ya proporcionados, duplicando esfuerzos y generando frustración. <br/> <br/> Con Beex, puedes implementar bots en WhatsApp que capturen datos clave y derive consultas automáticamente, ahorrando tiempo y reduciendo el abandono. Según Zippia, el 39% de los usuarios consideran frustrante repetir información en atención al cliente.</p>",
            image: ImageAccordionLeftOne
        },
        {
            title: "Centraliza las llamadas en un único número",
            description: "<p>En la actualidad, los clientes que llenan formularios en la web de Pandero reciben llamadas desde un número diferente al que figura como contacto oficial, lo que genera desconfianza y afecta la experiencia del cliente. La falta de consistencia en los números de contacto puede percibirse como una gestión desorganizada. <br/> <br/>  Con una plataforma omnicanal como Beex, puedes centralizar todas las llamadas en un único número y utilizar un sistema IVR inteligente para enrutar automáticamente cada consulta al departamento y agente adecuado. Esta estrategia no solo simplifica la experiencia para los clientes, sino que también fortalece la percepción de profesionalismo y confianza en la marca.</p>",
            image: ImageAccordionLeftTwo
        },
        {
            title: "Unifica la prospección con workflows personalizados",
            description: "<p>Actualmente, los procesos de prospección de inmuebles y vehículos se manejan por separado y con diferentes números de contacto, lo que dificulta la supervisión y genera ineficiencias. Con Beex, puedes crear una sola estrategia que automatice el primer contacto y distribuya las consultas a los agentes especializados mediante colas de atención. <br/> <br/> Esta integración permite manejar ambos productos financieros desde una única plataforma, simplificando la supervisión de los equipos de ventas y asegurando una experiencia homogénea para los prospectos.   </p>",
            image: ImageAccordionLeftThree,
        },
    ],
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Latam. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
}

export default data
