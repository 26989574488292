import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.png"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.png"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.png"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageFourMobile from "./images/imagesMobile/imageThree.webp"

const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Automatiza las preguntas frecuentes con bots",
      description: "Un aspecto clave faltante es la implementación de bots en la web y WhatsApp para responder preguntas frecuentes sobre Pandero. Esto no solo reduce la carga de trabajo repetitiva de los agentes, sino que también garantiza una atención disponible 24/7, mejorando significativamente la experiencia del cliente. <br /><br />Con Beex, puedes gestionar estos bots desde una única plataforma omnicanal, centralizando todas las interacciones y ofreciendo respuestas inmediatas a los clientes. Esta automatización no solo optimiza recursos, sino que también incrementa la retención al brindar un servicio continuo.",
      image: imageOneMobile,
      background: "#D3F7FA"
    },
    {
      title: "Mejora el seguimiento con workflows personalizados",
      description: "Un seguimiento efectivo para clientes interesados pero inactivos podría potenciar los resultados. Utilizando workflows con IA, puedes personalizar mensajes según la etapa del embudo en la que se encuentre cada prospecto. WhatsApp, con su tasa de apertura del 98%, es el canal ideal para estas comunicaciones. <br /><br />Por ejemplo, podrías enviar recordatorios y ofertas exclusivas dependiendo del interés del lead. Según Forbes, las campañas omnicanales tienen una tasa de engagement del 18.96%, en comparación con solo el 5.4% de las campañas de un solo canal, lo que evidencia la efectividad de integrar esta estrategia.",
      image: imageTwoMobile,
      background: "#FBE6E5"
    },
    {
      title: "Integra sistemas internos con canales de atención",
      description: "Actualmente, los canales de atención no están integrados con los sistemas internos, lo que genera duplicidad y una experiencia fragmentada para los clientes. Cada vez que un usuario se contacta desde un canal diferente, es tratado como un caso nuevo, lo que ralentiza el proceso. <br /><br />Con Beex, la integración permite a los agentes acceder al historial completo del cliente, continuando la conversación donde quedó. Esto acelera el embudo de ventas al eliminar repeticiones y crear una experiencia más fluida y eficiente.",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
  ]

  return (
    <section className="container-experience-pandero">
      <p className="container-experience-pandero-title">
        La plataforma omnicanal de Beex ayuda a las empresas a brindar una gran <TitleUnderlineOne underline="experiencia en" /> <TitleUnderlineTwo underline="servicio" /> <TitleUnderlineTwo underline="al cliente" />
      </p>
      <div className="container-experience-pandero-cardsOne" >
        <div>
          <section className="container-experience-pandero-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 6px 32px" }}>
            <section style={{ padding: "0px 26px" }}>
              <p className="container-experience-pandero-cardsOne-card-title">Automatiza las preguntas frecuentes con bots </p>
              <p className="container-experience-pandero-cardsOne-card-description">
                Un aspecto clave faltante es la implementación de bots en la web y WhatsApp para responder preguntas frecuentes sobre Pandero. Esto no solo reduce la carga de trabajo repetitiva de los agentes, sino que también garantiza una atención disponible 24/7, mejorando significativamente la experiencia del cliente.
                <br /><br />
                Con Beex, puedes gestionar estos bots desde una única plataforma omnicanal, centralizando todas las interacciones y ofreciendo respuestas inmediatas a los clientes. Esta automatización no solo optimiza recursos, sino que también incrementa la retención al brindar un servicio continuo.
              </p>
            </section>
            <section>
              <img
                className="container-experience-pandero-cardsOne-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        <div className="container-experience-pandero-cardsTwo">
          <div className="container-experience-pandero-cardsOne-column">
            <section className="container-experience-pandero-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
              <section>
                <p className="container-experience-pandero-cardsOne-card-title">Mejora el seguimiento con workflows personalizados</p>
                <p className="container-experience-pandero-cardsOne-card-description">
                  Un seguimiento efectivo para clientes interesados pero inactivos podría potenciar los resultados. Utilizando workflows con IA, puedes personalizar mensajes según la etapa del embudo en la que se encuentre cada prospecto. WhatsApp, con su tasa de apertura del 98%, es el canal ideal para estas comunicaciones.
                  <br /><br />
                  Por ejemplo, podrías enviar recordatorios y ofertas exclusivas dependiendo del interés del lead. Según Forbes, las campañas omnicanales tienen una tasa de engagement del 18.96%, en comparación con solo el 5.4% de las campañas de un solo canal, lo que evidencia la efectividad de integrar esta estrategia.
                </p>
              </section>
              <section>
                <img
                  className="container-experience-pandero-cardsOne-card-image"
                  src={imageTwoDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </div>
          <div className="container-experience-pandero-cardsTwo-cards-column">
            <section className="container-experience-pandero-cardsTwo-card" style={{ background: "#EEEAFB" }}>
              <section>
                <p className="container-experience-pandero-cardsTwo-card-title">Integra sistemas internos con canales de atención</p>
                <p className="container-experience-pandero-cardsTwo-card-description">
                  Actualmente, los canales de atención no están integrados con los sistemas internos, lo que genera duplicidad y una experiencia fragmentada para los clientes. Cada vez que un usuario se contacta desde un canal diferente, es tratado como un caso nuevo, lo que ralentiza el proceso.
                  <br /><br />
                  Con Beex, la integración permite a los agentes acceder al historial completo del cliente, continuando la conversación donde quedó. Esto acelera el embudo de ventas al eliminar repeticiones y crear una experiencia más fluida y eficiente.
                </p>
              </section>
              <section>
                <img
                  className="container-experience-pandero-cardsTwo-card-image"
                  src={imageThreeDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </div>
        </div>
      </div>

      <div className="container-experience-pandero-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-pandero-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-pandero-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-pandero-cards-mobile-card-description" dangerouslySetInnerHTML={{ __html: elemento.description }}>
                
                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-pandero-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
